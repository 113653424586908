// source: svc.sberzvuk.msg.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var job_pb = require('./job_pb.js');
goog.object.extend(proto, job_pb);
var media_pb = require('./media_pb.js');
goog.object.extend(proto, media_pb);
var error_pb = require('./error_pb.js');
goog.object.extend(proto, error_pb);
goog.exportSymbol('proto.SberzvukFindMediaRequest', null, global);
goog.exportSymbol('proto.SberzvukFindMediaResponse', null, global);
goog.exportSymbol('proto.SberzvukLibraryAddPlaylistWithTrackListRequest', null, global);
goog.exportSymbol('proto.SberzvukLibraryAddPlaylistWithTrackListResponse', null, global);
goog.exportSymbol('proto.SberzvukLibraryAddReleaseRequest', null, global);
goog.exportSymbol('proto.SberzvukLibraryAddReleaseResponse', null, global);
goog.exportSymbol('proto.SberzvukLibraryAddTrackRequest', null, global);
goog.exportSymbol('proto.SberzvukLibraryAddTrackResponse', null, global);
goog.exportSymbol('proto.SearchItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukFindMediaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SberzvukFindMediaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukFindMediaRequest.displayName = 'proto.SberzvukFindMediaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukFindMediaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SberzvukFindMediaResponse.repeatedFields_, null);
};
goog.inherits(proto.SberzvukFindMediaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukFindMediaResponse.displayName = 'proto.SberzvukFindMediaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SearchItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SearchItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchItem.displayName = 'proto.SearchItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukLibraryAddTrackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SberzvukLibraryAddTrackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukLibraryAddTrackRequest.displayName = 'proto.SberzvukLibraryAddTrackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukLibraryAddTrackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SberzvukLibraryAddTrackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukLibraryAddTrackResponse.displayName = 'proto.SberzvukLibraryAddTrackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SberzvukLibraryAddPlaylistWithTrackListRequest.repeatedFields_, null);
};
goog.inherits(proto.SberzvukLibraryAddPlaylistWithTrackListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukLibraryAddPlaylistWithTrackListRequest.displayName = 'proto.SberzvukLibraryAddPlaylistWithTrackListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SberzvukLibraryAddPlaylistWithTrackListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukLibraryAddPlaylistWithTrackListResponse.displayName = 'proto.SberzvukLibraryAddPlaylistWithTrackListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukLibraryAddReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SberzvukLibraryAddReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukLibraryAddReleaseRequest.displayName = 'proto.SberzvukLibraryAddReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SberzvukLibraryAddReleaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SberzvukLibraryAddReleaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SberzvukLibraryAddReleaseResponse.displayName = 'proto.SberzvukLibraryAddReleaseResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukFindMediaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukFindMediaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukFindMediaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukFindMediaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && job_pb.Task.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukFindMediaRequest}
 */
proto.SberzvukFindMediaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukFindMediaRequest;
  return proto.SberzvukFindMediaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukFindMediaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukFindMediaRequest}
 */
proto.SberzvukFindMediaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new job_pb.Task;
      reader.readMessage(value,job_pb.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukFindMediaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukFindMediaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukFindMediaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukFindMediaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      job_pb.Task.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional Task task = 1;
 * @return {?proto.Task}
 */
proto.SberzvukFindMediaRequest.prototype.getTask = function() {
  return /** @type{?proto.Task} */ (
    jspb.Message.getWrapperField(this, job_pb.Task, 1));
};


/**
 * @param {?proto.Task|undefined} value
 * @return {!proto.SberzvukFindMediaRequest} returns this
*/
proto.SberzvukFindMediaRequest.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukFindMediaRequest} returns this
 */
proto.SberzvukFindMediaRequest.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukFindMediaRequest.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.SberzvukFindMediaRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SberzvukFindMediaRequest} returns this
 */
proto.SberzvukFindMediaRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SberzvukFindMediaResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukFindMediaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukFindMediaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukFindMediaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukFindMediaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && job_pb.Task.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.SearchItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukFindMediaResponse}
 */
proto.SberzvukFindMediaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukFindMediaResponse;
  return proto.SberzvukFindMediaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukFindMediaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukFindMediaResponse}
 */
proto.SberzvukFindMediaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new job_pb.Task;
      reader.readMessage(value,job_pb.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 2:
      var value = new proto.SearchItem;
      reader.readMessage(value,proto.SearchItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukFindMediaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukFindMediaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukFindMediaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukFindMediaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      job_pb.Task.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.SearchItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional Task task = 1;
 * @return {?proto.Task}
 */
proto.SberzvukFindMediaResponse.prototype.getTask = function() {
  return /** @type{?proto.Task} */ (
    jspb.Message.getWrapperField(this, job_pb.Task, 1));
};


/**
 * @param {?proto.Task|undefined} value
 * @return {!proto.SberzvukFindMediaResponse} returns this
*/
proto.SberzvukFindMediaResponse.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukFindMediaResponse} returns this
 */
proto.SberzvukFindMediaResponse.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukFindMediaResponse.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SearchItem items = 2;
 * @return {!Array<!proto.SearchItem>}
 */
proto.SberzvukFindMediaResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.SearchItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SearchItem, 2));
};


/**
 * @param {!Array<!proto.SearchItem>} value
 * @return {!proto.SberzvukFindMediaResponse} returns this
*/
proto.SberzvukFindMediaResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.SearchItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SearchItem}
 */
proto.SberzvukFindMediaResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.SearchItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SberzvukFindMediaResponse} returns this
 */
proto.SberzvukFindMediaResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SearchItem.prototype.toObject = function(opt_includeInstance) {
  return proto.SearchItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SearchItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    aname: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchItem}
 */
proto.SearchItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchItem;
  return proto.SearchItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchItem}
 */
proto.SearchItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SearchItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SearchItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.SearchItem.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SearchItem} returns this
 */
proto.SearchItem.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 total = 2;
 * @return {number}
 */
proto.SearchItem.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SearchItem} returns this
 */
proto.SearchItem.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 id = 3;
 * @return {number}
 */
proto.SearchItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.SearchItem} returns this
 */
proto.SearchItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.SearchItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SearchItem} returns this
 */
proto.SearchItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string aname = 5;
 * @return {string}
 */
proto.SearchItem.prototype.getAname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SearchItem} returns this
 */
proto.SearchItem.prototype.setAname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukLibraryAddTrackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukLibraryAddTrackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukLibraryAddTrackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddTrackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && job_pb.Task.toObject(includeInstance, f),
    trackId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukLibraryAddTrackRequest}
 */
proto.SberzvukLibraryAddTrackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukLibraryAddTrackRequest;
  return proto.SberzvukLibraryAddTrackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukLibraryAddTrackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukLibraryAddTrackRequest}
 */
proto.SberzvukLibraryAddTrackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new job_pb.Task;
      reader.readMessage(value,job_pb.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukLibraryAddTrackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukLibraryAddTrackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukLibraryAddTrackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddTrackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      job_pb.Task.serializeBinaryToWriter
    );
  }
  f = message.getTrackId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional Task task = 1;
 * @return {?proto.Task}
 */
proto.SberzvukLibraryAddTrackRequest.prototype.getTask = function() {
  return /** @type{?proto.Task} */ (
    jspb.Message.getWrapperField(this, job_pb.Task, 1));
};


/**
 * @param {?proto.Task|undefined} value
 * @return {!proto.SberzvukLibraryAddTrackRequest} returns this
*/
proto.SberzvukLibraryAddTrackRequest.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddTrackRequest} returns this
 */
proto.SberzvukLibraryAddTrackRequest.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddTrackRequest.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 track_id = 2;
 * @return {number}
 */
proto.SberzvukLibraryAddTrackRequest.prototype.getTrackId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SberzvukLibraryAddTrackRequest} returns this
 */
proto.SberzvukLibraryAddTrackRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukLibraryAddTrackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukLibraryAddTrackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukLibraryAddTrackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddTrackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.SberzvukLibraryAddTrackRequest.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && error_pb.StatusResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukLibraryAddTrackResponse}
 */
proto.SberzvukLibraryAddTrackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukLibraryAddTrackResponse;
  return proto.SberzvukLibraryAddTrackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukLibraryAddTrackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukLibraryAddTrackResponse}
 */
proto.SberzvukLibraryAddTrackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SberzvukLibraryAddTrackRequest;
      reader.readMessage(value,proto.SberzvukLibraryAddTrackRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = new error_pb.StatusResponse;
      reader.readMessage(value,error_pb.StatusResponse.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukLibraryAddTrackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukLibraryAddTrackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukLibraryAddTrackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddTrackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SberzvukLibraryAddTrackRequest.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_pb.StatusResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional SberzvukLibraryAddTrackRequest request = 1;
 * @return {?proto.SberzvukLibraryAddTrackRequest}
 */
proto.SberzvukLibraryAddTrackResponse.prototype.getRequest = function() {
  return /** @type{?proto.SberzvukLibraryAddTrackRequest} */ (
    jspb.Message.getWrapperField(this, proto.SberzvukLibraryAddTrackRequest, 1));
};


/**
 * @param {?proto.SberzvukLibraryAddTrackRequest|undefined} value
 * @return {!proto.SberzvukLibraryAddTrackResponse} returns this
*/
proto.SberzvukLibraryAddTrackResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddTrackResponse} returns this
 */
proto.SberzvukLibraryAddTrackResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddTrackResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusResponse status = 2;
 * @return {?proto.StatusResponse}
 */
proto.SberzvukLibraryAddTrackResponse.prototype.getStatus = function() {
  return /** @type{?proto.StatusResponse} */ (
    jspb.Message.getWrapperField(this, error_pb.StatusResponse, 2));
};


/**
 * @param {?proto.StatusResponse|undefined} value
 * @return {!proto.SberzvukLibraryAddTrackResponse} returns this
*/
proto.SberzvukLibraryAddTrackResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddTrackResponse} returns this
 */
proto.SberzvukLibraryAddTrackResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddTrackResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukLibraryAddPlaylistWithTrackListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ctx: (f = msg.getCtx()) && job_pb.JobContext.toObject(includeInstance, f),
    playlist: (f = msg.getPlaylist()) && media_pb.PlaylistInfo.toObject(includeInstance, f),
    trackIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukLibraryAddPlaylistWithTrackListRequest;
  return proto.SberzvukLibraryAddPlaylistWithTrackListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new job_pb.JobContext;
      reader.readMessage(value,job_pb.JobContext.deserializeBinaryFromReader);
      msg.setCtx(value);
      break;
    case 2:
      var value = new media_pb.PlaylistInfo;
      reader.readMessage(value,media_pb.PlaylistInfo.deserializeBinaryFromReader);
      msg.setPlaylist(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTrackIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukLibraryAddPlaylistWithTrackListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCtx();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      job_pb.JobContext.serializeBinaryToWriter
    );
  }
  f = message.getPlaylist();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      media_pb.PlaylistInfo.serializeBinaryToWriter
    );
  }
  f = message.getTrackIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
};


/**
 * optional JobContext ctx = 1;
 * @return {?proto.JobContext}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.getCtx = function() {
  return /** @type{?proto.JobContext} */ (
    jspb.Message.getWrapperField(this, job_pb.JobContext, 1));
};


/**
 * @param {?proto.JobContext|undefined} value
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} returns this
*/
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.setCtx = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.clearCtx = function() {
  return this.setCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.hasCtx = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlaylistInfo playlist = 2;
 * @return {?proto.PlaylistInfo}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.getPlaylist = function() {
  return /** @type{?proto.PlaylistInfo} */ (
    jspb.Message.getWrapperField(this, media_pb.PlaylistInfo, 2));
};


/**
 * @param {?proto.PlaylistInfo|undefined} value
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} returns this
*/
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.setPlaylist = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.clearPlaylist = function() {
  return this.setPlaylist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.hasPlaylist = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint32 track_ids = 3;
 * @return {!Array<number>}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.getTrackIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.setTrackIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.addTrackIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListRequest} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListRequest.prototype.clearTrackIdsList = function() {
  return this.setTrackIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukLibraryAddPlaylistWithTrackListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.SberzvukLibraryAddPlaylistWithTrackListRequest.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && error_pb.StatusResponse.toObject(includeInstance, f),
    playlistId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukLibraryAddPlaylistWithTrackListResponse;
  return proto.SberzvukLibraryAddPlaylistWithTrackListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SberzvukLibraryAddPlaylistWithTrackListRequest;
      reader.readMessage(value,proto.SberzvukLibraryAddPlaylistWithTrackListRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = new error_pb.StatusResponse;
      reader.readMessage(value,error_pb.StatusResponse.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaylistId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukLibraryAddPlaylistWithTrackListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SberzvukLibraryAddPlaylistWithTrackListRequest.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_pb.StatusResponse.serializeBinaryToWriter
    );
  }
  f = message.getPlaylistId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional SberzvukLibraryAddPlaylistWithTrackListRequest request = 1;
 * @return {?proto.SberzvukLibraryAddPlaylistWithTrackListRequest}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.getRequest = function() {
  return /** @type{?proto.SberzvukLibraryAddPlaylistWithTrackListRequest} */ (
    jspb.Message.getWrapperField(this, proto.SberzvukLibraryAddPlaylistWithTrackListRequest, 1));
};


/**
 * @param {?proto.SberzvukLibraryAddPlaylistWithTrackListRequest|undefined} value
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} returns this
*/
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusResponse status = 2;
 * @return {?proto.StatusResponse}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.getStatus = function() {
  return /** @type{?proto.StatusResponse} */ (
    jspb.Message.getWrapperField(this, error_pb.StatusResponse, 2));
};


/**
 * @param {?proto.StatusResponse|undefined} value
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} returns this
*/
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string playlist_id = 3;
 * @return {string}
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.getPlaylistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SberzvukLibraryAddPlaylistWithTrackListResponse} returns this
 */
proto.SberzvukLibraryAddPlaylistWithTrackListResponse.prototype.setPlaylistId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukLibraryAddReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukLibraryAddReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukLibraryAddReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && job_pb.Task.toObject(includeInstance, f),
    releaseId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukLibraryAddReleaseRequest}
 */
proto.SberzvukLibraryAddReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukLibraryAddReleaseRequest;
  return proto.SberzvukLibraryAddReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukLibraryAddReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukLibraryAddReleaseRequest}
 */
proto.SberzvukLibraryAddReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new job_pb.Task;
      reader.readMessage(value,job_pb.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReleaseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukLibraryAddReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukLibraryAddReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukLibraryAddReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      job_pb.Task.serializeBinaryToWriter
    );
  }
  f = message.getReleaseId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional Task task = 1;
 * @return {?proto.Task}
 */
proto.SberzvukLibraryAddReleaseRequest.prototype.getTask = function() {
  return /** @type{?proto.Task} */ (
    jspb.Message.getWrapperField(this, job_pb.Task, 1));
};


/**
 * @param {?proto.Task|undefined} value
 * @return {!proto.SberzvukLibraryAddReleaseRequest} returns this
*/
proto.SberzvukLibraryAddReleaseRequest.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddReleaseRequest} returns this
 */
proto.SberzvukLibraryAddReleaseRequest.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddReleaseRequest.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 release_id = 2;
 * @return {number}
 */
proto.SberzvukLibraryAddReleaseRequest.prototype.getReleaseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SberzvukLibraryAddReleaseRequest} returns this
 */
proto.SberzvukLibraryAddReleaseRequest.prototype.setReleaseId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SberzvukLibraryAddReleaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SberzvukLibraryAddReleaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddReleaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.SberzvukLibraryAddReleaseRequest.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && error_pb.StatusResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SberzvukLibraryAddReleaseResponse}
 */
proto.SberzvukLibraryAddReleaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SberzvukLibraryAddReleaseResponse;
  return proto.SberzvukLibraryAddReleaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SberzvukLibraryAddReleaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SberzvukLibraryAddReleaseResponse}
 */
proto.SberzvukLibraryAddReleaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SberzvukLibraryAddReleaseRequest;
      reader.readMessage(value,proto.SberzvukLibraryAddReleaseRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = new error_pb.StatusResponse;
      reader.readMessage(value,error_pb.StatusResponse.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SberzvukLibraryAddReleaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SberzvukLibraryAddReleaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SberzvukLibraryAddReleaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SberzvukLibraryAddReleaseRequest.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_pb.StatusResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional SberzvukLibraryAddReleaseRequest request = 1;
 * @return {?proto.SberzvukLibraryAddReleaseRequest}
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.getRequest = function() {
  return /** @type{?proto.SberzvukLibraryAddReleaseRequest} */ (
    jspb.Message.getWrapperField(this, proto.SberzvukLibraryAddReleaseRequest, 1));
};


/**
 * @param {?proto.SberzvukLibraryAddReleaseRequest|undefined} value
 * @return {!proto.SberzvukLibraryAddReleaseResponse} returns this
*/
proto.SberzvukLibraryAddReleaseResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddReleaseResponse} returns this
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusResponse status = 2;
 * @return {?proto.StatusResponse}
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.getStatus = function() {
  return /** @type{?proto.StatusResponse} */ (
    jspb.Message.getWrapperField(this, error_pb.StatusResponse, 2));
};


/**
 * @param {?proto.StatusResponse|undefined} value
 * @return {!proto.SberzvukLibraryAddReleaseResponse} returns this
*/
proto.SberzvukLibraryAddReleaseResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SberzvukLibraryAddReleaseResponse} returns this
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SberzvukLibraryAddReleaseResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto);
