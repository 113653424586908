import axios from 'axios';

const ApiService = {
  get(url) {
    return axios.get(
      `${window.env.VUE_APP_API_URL}/${url}`
    );
  },
  post(url, params) {
    return axios.post(
      `${window.env.VUE_APP_API_URL}/${url}`,
      params
    );
  }
};

export default ApiService;
