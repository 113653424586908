import ApiService from "./api.service";

const PlatformsService = {
  getAllServices () {
    return ApiService.get('api/v1/platforms/');
  },
  getLibrary (platform) {
    return ApiService.get(`api/v1/library/${platform}/`);
  },
  createJob (platform, params) {
    return ApiService.post(`api/v1/run/${platform}`,params);
  },
  getStatus (jobId) {
    return ApiService.get(`api/v1/jobs/${jobId}/status`);
  }
};

export default PlatformsService;
