export default {
  "CHANGE_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите сервис"])},
  "HOME_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенесите в СберЗвук свою музыку из других аудиосервисов"])},
  "BUTTON_NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
  "SELECT_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите контент"])},
  "SELECT_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете перенести в СберЗвук треки, плейлисты и альбомы"])},
  "TRACK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Треки"])},
  "PLAYLIST_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плейлисты"])},
  "ALBUM_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альбомы"])},
  "SELECT_PAGE_POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить приложению получить доступ auth сервиса для получения токена авторизации пользователя в СберЗвук"])},
  "PROGRESS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат переноса"])},
  "PROGRESS_DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомьтесь с результатом переноса контента"])},
  "COMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "TRANSFER": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перенос (", _interpolate(_named("current")), " из ", _interpolate(_named("total")), ")"])},
  "LIBRARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избранное"])},
  "PLAYLIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плейлист"])},
  "COPY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
  "COPIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопированно"])},
  "ERROR_LIBRARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При запросе произошла ошибка, попробуйте позже"])},
  "LIBRARY_BLANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В избранном пусто"])},
  "MIGRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенос музыки"])}
}