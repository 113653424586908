<template>
  <div class="alert-error">
    <div class="alert-error__inner">
      <div class="alert-error__icon">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="10"
            r="10"
            fill="#CF471D"
          />
          <path
            d="M8.97441 13.298C8.97441 13.064 9.03075 12.8647 9.14341 12.7C9.26475 12.5353 9.49008 12.453 9.81941 12.453C10.1487 12.453 10.3741 12.5353 10.4954 12.7C10.6167 12.8647 10.6774 13.064 10.6774 13.298C10.6774 13.5233 10.6167 13.7183 10.4954 13.883C10.3741 14.0477 10.1487 14.13 9.81941 14.13C9.49008 14.13 9.26475 14.0477 9.14341 13.883C9.03075 13.7183 8.97441 13.5233 8.97441 13.298ZM9.23441 4.9H10.4044V11.01H9.23441V4.9Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="alert-error__text">
        <b>Невозможно установить соединение с сервером:</b>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>