/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var platform_pb = require('./platform_pb.js')

var user_pb = require('./user_pb.js')

var media_pb = require('./media_pb.js')

var job_pb = require('./job_pb.js')

var oauth_pb = require('./oauth_pb.js')

var error_pb = require('./error_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = require('./svc.core.rpc_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.CoreServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.CoreServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetPlatformsRequest,
 *   !proto.GetPlatformsResponse>}
 */
const methodDescriptor_CoreService_GetPlatforms = new grpc.web.MethodDescriptor(
  '/CoreService/GetPlatforms',
  grpc.web.MethodType.UNARY,
  proto.GetPlatformsRequest,
  proto.GetPlatformsResponse,
  /**
   * @param {!proto.GetPlatformsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetPlatformsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetPlatformsRequest,
 *   !proto.GetPlatformsResponse>}
 */
const methodInfo_CoreService_GetPlatforms = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GetPlatformsResponse,
  /**
   * @param {!proto.GetPlatformsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetPlatformsResponse.deserializeBinary
);


/**
 * @param {!proto.GetPlatformsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetPlatformsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetPlatformsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CoreServiceClient.prototype.getPlatforms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CoreService/GetPlatforms',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetPlatforms,
      callback);
};


/**
 * @param {!proto.GetPlatformsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetPlatformsResponse>}
 *     Promise that resolves to the response
 */
proto.CoreServicePromiseClient.prototype.getPlatforms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CoreService/GetPlatforms',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetPlatforms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetLoginUrlRequest,
 *   !proto.GetLoginUrlResponse>}
 */
const methodDescriptor_CoreService_GetLoginUrl = new grpc.web.MethodDescriptor(
  '/CoreService/GetLoginUrl',
  grpc.web.MethodType.UNARY,
  proto.GetLoginUrlRequest,
  proto.GetLoginUrlResponse,
  /**
   * @param {!proto.GetLoginUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetLoginUrlResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetLoginUrlRequest,
 *   !proto.GetLoginUrlResponse>}
 */
const methodInfo_CoreService_GetLoginUrl = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GetLoginUrlResponse,
  /**
   * @param {!proto.GetLoginUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetLoginUrlResponse.deserializeBinary
);


/**
 * @param {!proto.GetLoginUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetLoginUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetLoginUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CoreServiceClient.prototype.getLoginUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CoreService/GetLoginUrl',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetLoginUrl,
      callback);
};


/**
 * @param {!proto.GetLoginUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetLoginUrlResponse>}
 *     Promise that resolves to the response
 */
proto.CoreServicePromiseClient.prototype.getLoginUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CoreService/GetLoginUrl',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetLoginUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetUserProfileByOauthCodeRequest,
 *   !proto.GetUserProfileByOauthCodeResponse>}
 */
const methodDescriptor_CoreService_GetUserProfileByOauthCode = new grpc.web.MethodDescriptor(
  '/CoreService/GetUserProfileByOauthCode',
  grpc.web.MethodType.UNARY,
  proto.GetUserProfileByOauthCodeRequest,
  proto.GetUserProfileByOauthCodeResponse,
  /**
   * @param {!proto.GetUserProfileByOauthCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetUserProfileByOauthCodeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetUserProfileByOauthCodeRequest,
 *   !proto.GetUserProfileByOauthCodeResponse>}
 */
const methodInfo_CoreService_GetUserProfileByOauthCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GetUserProfileByOauthCodeResponse,
  /**
   * @param {!proto.GetUserProfileByOauthCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetUserProfileByOauthCodeResponse.deserializeBinary
);


/**
 * @param {!proto.GetUserProfileByOauthCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetUserProfileByOauthCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetUserProfileByOauthCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CoreServiceClient.prototype.getUserProfileByOauthCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CoreService/GetUserProfileByOauthCode',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetUserProfileByOauthCode,
      callback);
};


/**
 * @param {!proto.GetUserProfileByOauthCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetUserProfileByOauthCodeResponse>}
 *     Promise that resolves to the response
 */
proto.CoreServicePromiseClient.prototype.getUserProfileByOauthCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CoreService/GetUserProfileByOauthCode',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetUserProfileByOauthCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AddJobRequest,
 *   !proto.AddJobResponse>}
 */
const methodDescriptor_CoreService_AddJob = new grpc.web.MethodDescriptor(
  '/CoreService/AddJob',
  grpc.web.MethodType.UNARY,
  proto.AddJobRequest,
  proto.AddJobResponse,
  /**
   * @param {!proto.AddJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AddJobResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.AddJobRequest,
 *   !proto.AddJobResponse>}
 */
const methodInfo_CoreService_AddJob = new grpc.web.AbstractClientBase.MethodInfo(
  proto.AddJobResponse,
  /**
   * @param {!proto.AddJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AddJobResponse.deserializeBinary
);


/**
 * @param {!proto.AddJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.AddJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AddJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CoreServiceClient.prototype.addJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CoreService/AddJob',
      request,
      metadata || {},
      methodDescriptor_CoreService_AddJob,
      callback);
};


/**
 * @param {!proto.AddJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AddJobResponse>}
 *     Promise that resolves to the response
 */
proto.CoreServicePromiseClient.prototype.addJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CoreService/AddJob',
      request,
      metadata || {},
      methodDescriptor_CoreService_AddJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetJobByIdRequest,
 *   !proto.GetJobByIdResponse>}
 */
const methodDescriptor_CoreService_GetJobById = new grpc.web.MethodDescriptor(
  '/CoreService/GetJobById',
  grpc.web.MethodType.UNARY,
  proto.GetJobByIdRequest,
  proto.GetJobByIdResponse,
  /**
   * @param {!proto.GetJobByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetJobByIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetJobByIdRequest,
 *   !proto.GetJobByIdResponse>}
 */
const methodInfo_CoreService_GetJobById = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GetJobByIdResponse,
  /**
   * @param {!proto.GetJobByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetJobByIdResponse.deserializeBinary
);


/**
 * @param {!proto.GetJobByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetJobByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetJobByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CoreServiceClient.prototype.getJobById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CoreService/GetJobById',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetJobById,
      callback);
};


/**
 * @param {!proto.GetJobByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetJobByIdResponse>}
 *     Promise that resolves to the response
 */
proto.CoreServicePromiseClient.prototype.getJobById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CoreService/GetJobById',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetJobById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetJobStatusRequest,
 *   !proto.GetJobStatusResponse>}
 */
const methodDescriptor_CoreService_GetJobStatus = new grpc.web.MethodDescriptor(
  '/CoreService/GetJobStatus',
  grpc.web.MethodType.UNARY,
  proto.GetJobStatusRequest,
  proto.GetJobStatusResponse,
  /**
   * @param {!proto.GetJobStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetJobStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetJobStatusRequest,
 *   !proto.GetJobStatusResponse>}
 */
const methodInfo_CoreService_GetJobStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GetJobStatusResponse,
  /**
   * @param {!proto.GetJobStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetJobStatusResponse.deserializeBinary
);


/**
 * @param {!proto.GetJobStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetJobStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetJobStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.CoreServiceClient.prototype.getJobStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/CoreService/GetJobStatus',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetJobStatus,
      callback);
};


/**
 * @param {!proto.GetJobStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetJobStatusResponse>}
 *     Promise that resolves to the response
 */
proto.CoreServicePromiseClient.prototype.getJobStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/CoreService/GetJobStatus',
      request,
      metadata || {},
      methodDescriptor_CoreService_GetJobStatus);
};


module.exports = proto;

