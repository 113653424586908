// source: svc.core.msg.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var media_pb = require('./media_pb.js');
goog.object.extend(proto, media_pb);
var job_pb = require('./job_pb.js');
goog.object.extend(proto, job_pb);
goog.exportSymbol('proto.GetPlaylistTracksRequest', null, global);
goog.exportSymbol('proto.GetPlaylistTracksResponse', null, global);
goog.exportSymbol('proto.JobGetListMediaByMediaTypeRequest', null, global);
goog.exportSymbol('proto.JobGetListMediaByMediaTypeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.JobGetListMediaByMediaTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.JobGetListMediaByMediaTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.JobGetListMediaByMediaTypeRequest.displayName = 'proto.JobGetListMediaByMediaTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.JobGetListMediaByMediaTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.JobGetListMediaByMediaTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.JobGetListMediaByMediaTypeResponse.displayName = 'proto.JobGetListMediaByMediaTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetPlaylistTracksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetPlaylistTracksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetPlaylistTracksRequest.displayName = 'proto.GetPlaylistTracksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetPlaylistTracksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetPlaylistTracksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetPlaylistTracksResponse.displayName = 'proto.GetPlaylistTracksResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.JobGetListMediaByMediaTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.JobGetListMediaByMediaTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobGetListMediaByMediaTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    job: (f = msg.getJob()) && job_pb.Job.toObject(includeInstance, f),
    mediaType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.JobGetListMediaByMediaTypeRequest}
 */
proto.JobGetListMediaByMediaTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.JobGetListMediaByMediaTypeRequest;
  return proto.JobGetListMediaByMediaTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.JobGetListMediaByMediaTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.JobGetListMediaByMediaTypeRequest}
 */
proto.JobGetListMediaByMediaTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new job_pb.Job;
      reader.readMessage(value,job_pb.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 2:
      var value = /** @type {!proto.MediaType} */ (reader.readEnum());
      msg.setMediaType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.JobGetListMediaByMediaTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.JobGetListMediaByMediaTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobGetListMediaByMediaTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      job_pb.Job.serializeBinaryToWriter
    );
  }
  f = message.getMediaType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional Job job = 1;
 * @return {?proto.Job}
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.getJob = function() {
  return /** @type{?proto.Job} */ (
    jspb.Message.getWrapperField(this, job_pb.Job, 1));
};


/**
 * @param {?proto.Job|undefined} value
 * @return {!proto.JobGetListMediaByMediaTypeRequest} returns this
*/
proto.JobGetListMediaByMediaTypeRequest.prototype.setJob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.JobGetListMediaByMediaTypeRequest} returns this
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.clearJob = function() {
  return this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.hasJob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MediaType media_type = 2;
 * @return {!proto.MediaType}
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.getMediaType = function() {
  return /** @type {!proto.MediaType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.MediaType} value
 * @return {!proto.JobGetListMediaByMediaTypeRequest} returns this
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.setMediaType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 offset = 3;
 * @return {number}
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.JobGetListMediaByMediaTypeRequest} returns this
 */
proto.JobGetListMediaByMediaTypeRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.JobGetListMediaByMediaTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.JobGetListMediaByMediaTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobGetListMediaByMediaTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.JobGetListMediaByMediaTypeRequest.toObject(includeInstance, f),
    tracksMap: (f = msg.getTracksMap()) ? f.toObject(includeInstance, proto.TrackInfo.toObject) : [],
    playlistsMap: (f = msg.getPlaylistsMap()) ? f.toObject(includeInstance, proto.PlaylistInfo.toObject) : [],
    albumsMap: (f = msg.getAlbumsMap()) ? f.toObject(includeInstance, proto.AlbumInfo.toObject) : [],
    total: jspb.Message.getFieldWithDefault(msg, 5, 0),
    next: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.JobGetListMediaByMediaTypeResponse}
 */
proto.JobGetListMediaByMediaTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.JobGetListMediaByMediaTypeResponse;
  return proto.JobGetListMediaByMediaTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.JobGetListMediaByMediaTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.JobGetListMediaByMediaTypeResponse}
 */
proto.JobGetListMediaByMediaTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.JobGetListMediaByMediaTypeRequest;
      reader.readMessage(value,proto.JobGetListMediaByMediaTypeRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = msg.getTracksMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.TrackInfo.deserializeBinaryFromReader, 0, new proto.TrackInfo());
         });
      break;
    case 3:
      var value = msg.getPlaylistsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.PlaylistInfo.deserializeBinaryFromReader, 0, new proto.PlaylistInfo());
         });
      break;
    case 4:
      var value = msg.getAlbumsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.AlbumInfo.deserializeBinaryFromReader, 0, new proto.AlbumInfo());
         });
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.JobGetListMediaByMediaTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.JobGetListMediaByMediaTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobGetListMediaByMediaTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.JobGetListMediaByMediaTypeRequest.serializeBinaryToWriter
    );
  }
  f = message.getTracksMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.TrackInfo.serializeBinaryToWriter);
  }
  f = message.getPlaylistsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.PlaylistInfo.serializeBinaryToWriter);
  }
  f = message.getAlbumsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.AlbumInfo.serializeBinaryToWriter);
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getNext();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional JobGetListMediaByMediaTypeRequest request = 1;
 * @return {?proto.JobGetListMediaByMediaTypeRequest}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.getRequest = function() {
  return /** @type{?proto.JobGetListMediaByMediaTypeRequest} */ (
    jspb.Message.getWrapperField(this, proto.JobGetListMediaByMediaTypeRequest, 1));
};


/**
 * @param {?proto.JobGetListMediaByMediaTypeRequest|undefined} value
 * @return {!proto.JobGetListMediaByMediaTypeResponse} returns this
*/
proto.JobGetListMediaByMediaTypeResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.JobGetListMediaByMediaTypeResponse} returns this
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<uint32, TrackInfo> tracks = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.TrackInfo>}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.getTracksMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.TrackInfo>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.TrackInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.JobGetListMediaByMediaTypeResponse} returns this
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.clearTracksMap = function() {
  this.getTracksMap().clear();
  return this;};


/**
 * map<uint32, PlaylistInfo> playlists = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.PlaylistInfo>}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.getPlaylistsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.PlaylistInfo>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.PlaylistInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.JobGetListMediaByMediaTypeResponse} returns this
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.clearPlaylistsMap = function() {
  this.getPlaylistsMap().clear();
  return this;};


/**
 * map<uint32, AlbumInfo> albums = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.AlbumInfo>}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.getAlbumsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.AlbumInfo>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.AlbumInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.JobGetListMediaByMediaTypeResponse} returns this
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.clearAlbumsMap = function() {
  this.getAlbumsMap().clear();
  return this;};


/**
 * optional uint32 total = 5;
 * @return {number}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.JobGetListMediaByMediaTypeResponse} returns this
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string next = 6;
 * @return {string}
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.getNext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.JobGetListMediaByMediaTypeResponse} returns this
 */
proto.JobGetListMediaByMediaTypeResponse.prototype.setNext = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetPlaylistTracksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetPlaylistTracksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetPlaylistTracksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetPlaylistTracksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ctx: (f = msg.getCtx()) && job_pb.JobContext.toObject(includeInstance, f),
    playlistId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetPlaylistTracksRequest}
 */
proto.GetPlaylistTracksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetPlaylistTracksRequest;
  return proto.GetPlaylistTracksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetPlaylistTracksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetPlaylistTracksRequest}
 */
proto.GetPlaylistTracksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new job_pb.JobContext;
      reader.readMessage(value,job_pb.JobContext.deserializeBinaryFromReader);
      msg.setCtx(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaylistId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetPlaylistTracksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetPlaylistTracksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetPlaylistTracksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetPlaylistTracksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCtx();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      job_pb.JobContext.serializeBinaryToWriter
    );
  }
  f = message.getPlaylistId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional JobContext ctx = 1;
 * @return {?proto.JobContext}
 */
proto.GetPlaylistTracksRequest.prototype.getCtx = function() {
  return /** @type{?proto.JobContext} */ (
    jspb.Message.getWrapperField(this, job_pb.JobContext, 1));
};


/**
 * @param {?proto.JobContext|undefined} value
 * @return {!proto.GetPlaylistTracksRequest} returns this
*/
proto.GetPlaylistTracksRequest.prototype.setCtx = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetPlaylistTracksRequest} returns this
 */
proto.GetPlaylistTracksRequest.prototype.clearCtx = function() {
  return this.setCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetPlaylistTracksRequest.prototype.hasCtx = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string playlist_id = 2;
 * @return {string}
 */
proto.GetPlaylistTracksRequest.prototype.getPlaylistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetPlaylistTracksRequest} returns this
 */
proto.GetPlaylistTracksRequest.prototype.setPlaylistId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 offset = 3;
 * @return {number}
 */
proto.GetPlaylistTracksRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetPlaylistTracksRequest} returns this
 */
proto.GetPlaylistTracksRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetPlaylistTracksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetPlaylistTracksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetPlaylistTracksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetPlaylistTracksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.GetPlaylistTracksRequest.toObject(includeInstance, f),
    tracksMap: (f = msg.getTracksMap()) ? f.toObject(includeInstance, proto.TrackInfo.toObject) : [],
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetPlaylistTracksResponse}
 */
proto.GetPlaylistTracksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetPlaylistTracksResponse;
  return proto.GetPlaylistTracksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetPlaylistTracksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetPlaylistTracksResponse}
 */
proto.GetPlaylistTracksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GetPlaylistTracksRequest;
      reader.readMessage(value,proto.GetPlaylistTracksRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = msg.getTracksMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.TrackInfo.deserializeBinaryFromReader, 0, new proto.TrackInfo());
         });
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetPlaylistTracksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetPlaylistTracksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetPlaylistTracksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetPlaylistTracksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GetPlaylistTracksRequest.serializeBinaryToWriter
    );
  }
  f = message.getTracksMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.TrackInfo.serializeBinaryToWriter);
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional GetPlaylistTracksRequest request = 1;
 * @return {?proto.GetPlaylistTracksRequest}
 */
proto.GetPlaylistTracksResponse.prototype.getRequest = function() {
  return /** @type{?proto.GetPlaylistTracksRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetPlaylistTracksRequest, 1));
};


/**
 * @param {?proto.GetPlaylistTracksRequest|undefined} value
 * @return {!proto.GetPlaylistTracksResponse} returns this
*/
proto.GetPlaylistTracksResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetPlaylistTracksResponse} returns this
 */
proto.GetPlaylistTracksResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetPlaylistTracksResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<uint32, TrackInfo> tracks = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.TrackInfo>}
 */
proto.GetPlaylistTracksResponse.prototype.getTracksMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.TrackInfo>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.TrackInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.GetPlaylistTracksResponse} returns this
 */
proto.GetPlaylistTracksResponse.prototype.clearTracksMap = function() {
  this.getTracksMap().clear();
  return this;};


/**
 * optional uint32 total = 3;
 * @return {number}
 */
proto.GetPlaylistTracksResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetPlaylistTracksResponse} returns this
 */
proto.GetPlaylistTracksResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto);
