import { createRouter, createWebHistory } from 'vue-router';
import ZvukAuth from "@/views/ZvukAuth";

function progressPageMiddleware ({ next }) {
  if (localStorage.getItem('job_id')) {
    next();
  } else {
    next({
      name: 'Home'
    });
  }
}

function jobProcessMiddleware ({ next }) {
  if (localStorage.getItem('job_id')) {
    next({
      name: 'Progress'
    });
  } else {
    next();
  }
}

const routes = [
  {
    path: '/run',
    name: 'Home',
    component: ZvukAuth,
    meta: {
      middleware: [jobProcessMiddleware]
    }
  },
  // {
  //   path: '/select',
  //   name: 'Select',
  //   component: () => import('../views/Select.vue'),
  //   meta: {
  //     middleware: [jobProcessMiddleware]
  //   }
  // },
  // {
  //   path: '/library',
  //   name: 'Library',
  //   component: () => import('../views/Library.vue'),
  //   meta: {
  //     middleware: [jobProcessMiddleware]
  //   }
  // },
  // {
  //   path: '/progress',
  //   name: 'Progress',
  //   component: () => import('../views/Progress.vue'),
  //   meta: {
  //     middleware: [progressPageMiddleware]
  //   }
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next
  };

  return middleware[0]({
    ...context
  });
});

export default router;
