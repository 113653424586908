// source: job.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var media_pb = require('./media_pb.js');
goog.object.extend(proto, media_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
goog.exportSymbol('proto.Job', null, global);
goog.exportSymbol('proto.JobContext', null, global);
goog.exportSymbol('proto.JobStatusType', null, global);
goog.exportSymbol('proto.Task', null, global);
goog.exportSymbol('proto.TaskStatusType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.JobContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.JobContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.JobContext.displayName = 'proto.JobContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Job.repeatedFields_, null);
};
goog.inherits(proto.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Job.displayName = 'proto.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Task.displayName = 'proto.Task';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.JobContext.prototype.toObject = function(opt_includeInstance) {
  return proto.JobContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.JobContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userSrc: (f = msg.getUserSrc()) && user_pb.User.toObject(includeInstance, f),
    userDst: (f = msg.getUserDst()) && user_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.JobContext}
 */
proto.JobContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.JobContext;
  return proto.JobContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.JobContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.JobContext}
 */
proto.JobContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setUserSrc(value);
      break;
    case 3:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setUserDst(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.JobContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.JobContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.JobContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserSrc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUserDst();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.JobContext.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.JobContext} returns this
 */
proto.JobContext.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user_src = 2;
 * @return {?proto.User}
 */
proto.JobContext.prototype.getUserSrc = function() {
  return /** @type{?proto.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 2));
};


/**
 * @param {?proto.User|undefined} value
 * @return {!proto.JobContext} returns this
*/
proto.JobContext.prototype.setUserSrc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.JobContext} returns this
 */
proto.JobContext.prototype.clearUserSrc = function() {
  return this.setUserSrc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.JobContext.prototype.hasUserSrc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional User user_dst = 3;
 * @return {?proto.User}
 */
proto.JobContext.prototype.getUserDst = function() {
  return /** @type{?proto.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 3));
};


/**
 * @param {?proto.User|undefined} value
 * @return {!proto.JobContext} returns this
*/
proto.JobContext.prototype.setUserDst = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.JobContext} returns this
 */
proto.JobContext.prototype.clearUserDst = function() {
  return this.setUserDst(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.JobContext.prototype.hasUserDst = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Job.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.JobContext.toObject(includeInstance, f),
    mediaList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Job}
 */
proto.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Job;
  return proto.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Job}
 */
proto.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.JobContext;
      reader.readMessage(value,proto.JobContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.MediaType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMedia(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.JobContext.serializeBinaryToWriter
    );
  }
  f = message.getMediaList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional JobContext context = 1;
 * @return {?proto.JobContext}
 */
proto.Job.prototype.getContext = function() {
  return /** @type{?proto.JobContext} */ (
    jspb.Message.getWrapperField(this, proto.JobContext, 1));
};


/**
 * @param {?proto.JobContext|undefined} value
 * @return {!proto.Job} returns this
*/
proto.Job.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Job} returns this
 */
proto.Job.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Job.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MediaType media = 2;
 * @return {!Array<!proto.MediaType>}
 */
proto.Job.prototype.getMediaList = function() {
  return /** @type {!Array<!proto.MediaType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.MediaType>} value
 * @return {!proto.Job} returns this
 */
proto.Job.prototype.setMediaList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.MediaType} value
 * @param {number=} opt_index
 * @return {!proto.Job} returns this
 */
proto.Job.prototype.addMedia = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Job} returns this
 */
proto.Job.prototype.clearMediaList = function() {
  return this.setMediaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.JobContext.toObject(includeInstance, f),
    mediaType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    track: (f = msg.getTrack()) && media_pb.TrackInfo.toObject(includeInstance, f),
    playlist: (f = msg.getPlaylist()) && media_pb.PlaylistInfo.toObject(includeInstance, f),
    album: (f = msg.getAlbum()) && media_pb.AlbumInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Task}
 */
proto.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Task;
  return proto.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Task}
 */
proto.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.JobContext;
      reader.readMessage(value,proto.JobContext.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 5:
      var value = /** @type {!proto.MediaType} */ (reader.readEnum());
      msg.setMediaType(value);
      break;
    case 6:
      var value = new media_pb.TrackInfo;
      reader.readMessage(value,media_pb.TrackInfo.deserializeBinaryFromReader);
      msg.setTrack(value);
      break;
    case 7:
      var value = new media_pb.PlaylistInfo;
      reader.readMessage(value,media_pb.PlaylistInfo.deserializeBinaryFromReader);
      msg.setPlaylist(value);
      break;
    case 8:
      var value = new media_pb.AlbumInfo;
      reader.readMessage(value,media_pb.AlbumInfo.deserializeBinaryFromReader);
      msg.setAlbum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.JobContext.serializeBinaryToWriter
    );
  }
  f = message.getMediaType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTrack();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      media_pb.TrackInfo.serializeBinaryToWriter
    );
  }
  f = message.getPlaylist();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      media_pb.PlaylistInfo.serializeBinaryToWriter
    );
  }
  f = message.getAlbum();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      media_pb.AlbumInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobContext context = 1;
 * @return {?proto.JobContext}
 */
proto.Task.prototype.getContext = function() {
  return /** @type{?proto.JobContext} */ (
    jspb.Message.getWrapperField(this, proto.JobContext, 1));
};


/**
 * @param {?proto.JobContext|undefined} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Task.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MediaType media_type = 5;
 * @return {!proto.MediaType}
 */
proto.Task.prototype.getMediaType = function() {
  return /** @type {!proto.MediaType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.MediaType} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setMediaType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional TrackInfo track = 6;
 * @return {?proto.TrackInfo}
 */
proto.Task.prototype.getTrack = function() {
  return /** @type{?proto.TrackInfo} */ (
    jspb.Message.getWrapperField(this, media_pb.TrackInfo, 6));
};


/**
 * @param {?proto.TrackInfo|undefined} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setTrack = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearTrack = function() {
  return this.setTrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Task.prototype.hasTrack = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PlaylistInfo playlist = 7;
 * @return {?proto.PlaylistInfo}
 */
proto.Task.prototype.getPlaylist = function() {
  return /** @type{?proto.PlaylistInfo} */ (
    jspb.Message.getWrapperField(this, media_pb.PlaylistInfo, 7));
};


/**
 * @param {?proto.PlaylistInfo|undefined} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setPlaylist = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearPlaylist = function() {
  return this.setPlaylist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Task.prototype.hasPlaylist = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AlbumInfo album = 8;
 * @return {?proto.AlbumInfo}
 */
proto.Task.prototype.getAlbum = function() {
  return /** @type{?proto.AlbumInfo} */ (
    jspb.Message.getWrapperField(this, media_pb.AlbumInfo, 8));
};


/**
 * @param {?proto.AlbumInfo|undefined} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setAlbum = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearAlbum = function() {
  return this.setAlbum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Task.prototype.hasAlbum = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * @enum {number}
 */
proto.JobStatusType = {
  JOBSTATUSUNKNOWN: 0,
  JOBSTATUSCREATED: 1,
  JOBSTATUSPROCESSED: 2,
  JOBSTATUSFINISHED: 3
};

/**
 * @enum {number}
 */
proto.TaskStatusType = {
  TASKSTATUSUNKNOWN: 0,
  TASKSTATUSCREATED: 1,
  TASKSTATUSPROCESSED: 2,
  TASKSTATUSFINISHED: 3
};

goog.object.extend(exports, proto);
