<template>
  <div class="zvukLayout" :class="layoutClasses">
    <div class="zvukLayout__inner" v-if="currentState">
      <div class="zvukLayout__spinner" v-if="currentState === STATE.LOADING"></div>
      <div class="zvukLayout__icon" v-if="icon">
        <img :src="require(`@/assets/${icon}`)" alt="">
      </div>
      <div class="zvukLayout__title" v-if="title">{{ title }}</div>
      <div class="zvukLayout__desc" v-if="desc">{{ desc }}</div>
      <div class="zvukLayout__button">
        <button
          v-if="currentState === STATE.COMPLETE"
          class="is--primary"
          @click="openZvuk"
        >Перейти в коллекцию</button>
        <button
            v-if="currentState === STATE.ERROR || currentState === STATE.AUTH_ERROR"
            class="is--secondary"
            @click="tryAgain"
        >Начать сначала</button>
      </div>
      <div class="zvukLayout__retry" v-if="desc">
        <a href="https://zvuk.com/migrations">Перенести еще плейлисты</a>
      </div>
    </div>
  </div>
</template>

<script>
import {JOB_CREATE} from "@/store/actions.type";

const STATE = Object.freeze({
  AUTH: 'auth',
  LOADING: 'loading',
  COMPLETE: 'complete',
  ERROR: 'error',
  AUTH_ERROR: 'authError',
  NOTSUPPORTED: 'notsupported'
});

const availablePlatforms = ['apple', 'spotify', 'deezer', 'yandex', 'vk'];
const availablePlatformsNames = Object.freeze({
  apple:'Apple Music',
  spotify: 'Spotify',
  deezer: 'Deezer',
  yandex: 'Яндекс Музыки',
  vk: 'VK Музыки'
});

export default {
  data() {
    return {
      STATE,
      availablePlatformsNames,
      currentState: null,
      authTimeout: null,
    };
  },
  mounted() {
    console.log('Zvuk mounted');
    const { platform, userid, playlists, state } = this.$route.query;
    console.log(`query ${JSON.stringify(this.$route.query)}`);

    if (platform && availablePlatforms.findIndex(p => p === platform) !== -1) {
      this.currentState = STATE.LOADING;

      this.$store.dispatch(JOB_CREATE, { platform, userid, playlists, state }).then(() => {
        this.currentState = STATE.COMPLETE;

        const url = new URL(location.href);
        url.searchParams.delete('state');
        history.pushState({},window.document.title, url.href);
      }).catch(error => {
        if (error.response.status === 401) {
          // unauthorized source platform
          if (error.response.data.code === 401 || error.response.data.code === 404 || error.response.data.code === 1401) {
            let redirect = `${window.env.VUE_APP_API_URL}/auth/${platform}/login?`;

            if (userid !== undefined) {
              redirect += `&userid=${userid}`;
            }

            if (playlists !== undefined) {
              redirect += `&playlists=${playlists}`;
            }

            if (error.response.data.state !== "") {
              redirect += `&state=${error.response.data.state}`;
            }

            window.location.href = redirect;

            // unauthorized destination platform
          } else if (error.response.data.code === 2401) {
            this.currentState = STATE.AUTH;
            clearTimeout(this.authTimeout);

            this.authTimeout = setTimeout(() => {
              let redirect = `${window.env.VUE_APP_API_URL}/auth/sberzvuk/login?src=${platform}`;

              if (userid !== undefined) {
                redirect += `&userid=${userid}`;
              }

              if (playlists !== undefined) {
                redirect += `&playlists=${playlists}`;
              }

              if (error.response.data.state !== "") {
                redirect += `&state=${error.response.data.state}`;
              }

              window.location.href = redirect;
            }, 0);
          }
        } else if (error.response.status === 500 && error.response.data.code === 1500) {
            this.currentState = STATE.AUTH_ERROR;
        } else {
          this.currentState = STATE.ERROR;
        }
      });
    } else {
      console.warn('not supported platform:',platform);
      this.currentState = STATE.NOTSUPPORTED;
    }
  },
  methods: {
    tryAgain() {
      const { platform, userid, playlists, state } = this.$route.query;
      console.log(`query ${JSON.stringify(this.$route.query)}`);

      if (platform && availablePlatforms.findIndex(p => p === platform) !== -1) {
        let redirect = `${window.location.origin}/run?platform=${platform}`;
        if (userid !== undefined) {
          redirect += `&userid=${userid}`;
        }

        if (playlists !== undefined) {
          redirect += `&playlists=${playlists}`;
        }

        if (state !== undefined) {
          redirect += `&state=${state}`;
        }

        window.location.href = redirect;
      }
    },
    openZvuk() {
      window.location.href = 'https://open.zvuk.com/cLQ0/yntja563';
    }
  },
  computed: {
    layoutClasses() {
      return [`is--${this.currentState}`];
    },
    icon() {
      const icons = {
        [STATE.AUTH]: 'zvook_logo_small.svg',
        [STATE.COMPLETE]: 'complete.svg',
        [STATE.ERROR]: 'error.svg',
        [STATE.AUTH_ERROR]: 'error.svg',
        [STATE.NOTSUPPORTED]: 'error.svg',
      };

      return icons[this.currentState] || null;
    },
    title() {
      const titles = {
        [STATE.AUTH]: 'Авторизуйтесь в Звуке',
        [STATE.ERROR]: 'Обработка не завершена',
        [STATE.AUTH_ERROR]: 'Не удалось авторизоваться',
        [STATE.COMPLETE]: 'Готово!',
        [STATE.LOADING]: 'Обрабатываем медиатеку',
        [STATE.NOTSUPPORTED]: 'Сервис не поддерживается',
      };

      return titles[this.currentState] || null;
    },
    platformName() {
      const { platform } = this.$route.query;

      if (platform && availablePlatforms.findIndex(p => p === platform) !== -1) {
        return availablePlatformsNames[platform];
      }
      return '';
    },
    desc() {
      const descs = {
        [STATE.AUTH]: 'И мы начнём переносить вашу медиатеку',
        [STATE.COMPLETE]: 'Ваши плейлисты из ' + this.platformName + ' перенесены, \nищите их в разделе «Моя коллекция» ',
        [STATE.NOTSUPPORTED]: 'Перенос из выбранного сервиса не поддерживается',
      };
      return descs[this.currentState] || null;
    },
  }
};
</script>