<template>
  <div class="root">
    <router-view />
    <ErrorMessage v-if="status.error" :text="status.error" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {PLATFORMS_GET} from "./store/actions.type";
import ErrorMessage from "./components/ErrorMessage";

export default {
  components: {ErrorMessage},
  computed: {
    ...mapGetters(['status']),
  },
  mounted() {
    // Promise.all([
    //   this.$store.dispatch(PLATFORMS_GET)
    // ]);
  },
};
</script>

<style lang="scss">
  @import "styles/main";

  .alert-error {
    position: fixed;
    bottom: 34px;
    left: 50%;
    transform: translate(-50%, 0);
    &__inner {
      display: flex;
      align-items: flex-start;
      background: #692727;
      padding: 20px 26px 20px 20px;
      border-radius: 10px;
      color: #F09CA0;
    }
    &__text {
      margin-left: 10px;
      font-size: 14px;
      line-height: 22px;
      b {
        font-weight: 500;
        margin-bottom: 20px;
        display: block;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          font-weight: 400;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .alert-error {
      width: 340px;
      &__inner {
        padding: 14px 20px 16px 14px;
      }
      &__text {
        font-size: 11px;
        line-height: 16px;
        b {
          margin-bottom: 4px;
        }
      }
    }
  }
</style>
