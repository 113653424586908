/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var user_pb = require('./user_pb.js')

var oauth_pb = require('./oauth_pb.js')

var media_pb = require('./media_pb.js')
const proto = require('./svc.spotify.rpc_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.SpotifyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.SpotifyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GenerateAuthUrlRequest,
 *   !proto.GenerateAuthUrlResponse>}
 */
const methodDescriptor_SpotifyService_GenerateAuthUrl = new grpc.web.MethodDescriptor(
  '/SpotifyService/GenerateAuthUrl',
  grpc.web.MethodType.UNARY,
  proto.GenerateAuthUrlRequest,
  proto.GenerateAuthUrlResponse,
  /**
   * @param {!proto.GenerateAuthUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenerateAuthUrlResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GenerateAuthUrlRequest,
 *   !proto.GenerateAuthUrlResponse>}
 */
const methodInfo_SpotifyService_GenerateAuthUrl = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GenerateAuthUrlResponse,
  /**
   * @param {!proto.GenerateAuthUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenerateAuthUrlResponse.deserializeBinary
);


/**
 * @param {!proto.GenerateAuthUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GenerateAuthUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenerateAuthUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SpotifyServiceClient.prototype.generateAuthUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SpotifyService/GenerateAuthUrl',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_GenerateAuthUrl,
      callback);
};


/**
 * @param {!proto.GenerateAuthUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenerateAuthUrlResponse>}
 *     Promise that resolves to the response
 */
proto.SpotifyServicePromiseClient.prototype.generateAuthUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SpotifyService/GenerateAuthUrl',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_GenerateAuthUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ExchangeOauthCodeToAccessTokenRequest,
 *   !proto.ExchangeOauthCodeToAccessTokenResponse>}
 */
const methodDescriptor_SpotifyService_ExchangeOauthCodeToAccessToken = new grpc.web.MethodDescriptor(
  '/SpotifyService/ExchangeOauthCodeToAccessToken',
  grpc.web.MethodType.UNARY,
  proto.ExchangeOauthCodeToAccessTokenRequest,
  proto.ExchangeOauthCodeToAccessTokenResponse,
  /**
   * @param {!proto.ExchangeOauthCodeToAccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ExchangeOauthCodeToAccessTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ExchangeOauthCodeToAccessTokenRequest,
 *   !proto.ExchangeOauthCodeToAccessTokenResponse>}
 */
const methodInfo_SpotifyService_ExchangeOauthCodeToAccessToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.ExchangeOauthCodeToAccessTokenResponse,
  /**
   * @param {!proto.ExchangeOauthCodeToAccessTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ExchangeOauthCodeToAccessTokenResponse.deserializeBinary
);


/**
 * @param {!proto.ExchangeOauthCodeToAccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ExchangeOauthCodeToAccessTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ExchangeOauthCodeToAccessTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SpotifyServiceClient.prototype.exchangeOauthCodeToAccessToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SpotifyService/ExchangeOauthCodeToAccessToken',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_ExchangeOauthCodeToAccessToken,
      callback);
};


/**
 * @param {!proto.ExchangeOauthCodeToAccessTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ExchangeOauthCodeToAccessTokenResponse>}
 *     Promise that resolves to the response
 */
proto.SpotifyServicePromiseClient.prototype.exchangeOauthCodeToAccessToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SpotifyService/ExchangeOauthCodeToAccessToken',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_ExchangeOauthCodeToAccessToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetUserProfileRequest,
 *   !proto.GetUserProfileResponse>}
 */
const methodDescriptor_SpotifyService_GetUserProfile = new grpc.web.MethodDescriptor(
  '/SpotifyService/GetUserProfile',
  grpc.web.MethodType.UNARY,
  proto.GetUserProfileRequest,
  proto.GetUserProfileResponse,
  /**
   * @param {!proto.GetUserProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetUserProfileResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetUserProfileRequest,
 *   !proto.GetUserProfileResponse>}
 */
const methodInfo_SpotifyService_GetUserProfile = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GetUserProfileResponse,
  /**
   * @param {!proto.GetUserProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetUserProfileResponse.deserializeBinary
);


/**
 * @param {!proto.GetUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetUserProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetUserProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SpotifyServiceClient.prototype.getUserProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SpotifyService/GetUserProfile',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_GetUserProfile,
      callback);
};


/**
 * @param {!proto.GetUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetUserProfileResponse>}
 *     Promise that resolves to the response
 */
proto.SpotifyServicePromiseClient.prototype.getUserProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SpotifyService/GetUserProfile',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_GetUserProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetMediaTotalCountRequest,
 *   !proto.GetMediaTotalCountResponse>}
 */
const methodDescriptor_SpotifyService_GetMediaTotalCount = new grpc.web.MethodDescriptor(
  '/SpotifyService/GetMediaTotalCount',
  grpc.web.MethodType.UNARY,
  proto.GetMediaTotalCountRequest,
  proto.GetMediaTotalCountResponse,
  /**
   * @param {!proto.GetMediaTotalCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetMediaTotalCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetMediaTotalCountRequest,
 *   !proto.GetMediaTotalCountResponse>}
 */
const methodInfo_SpotifyService_GetMediaTotalCount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GetMediaTotalCountResponse,
  /**
   * @param {!proto.GetMediaTotalCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GetMediaTotalCountResponse.deserializeBinary
);


/**
 * @param {!proto.GetMediaTotalCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetMediaTotalCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetMediaTotalCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SpotifyServiceClient.prototype.getMediaTotalCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SpotifyService/GetMediaTotalCount',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_GetMediaTotalCount,
      callback);
};


/**
 * @param {!proto.GetMediaTotalCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetMediaTotalCountResponse>}
 *     Promise that resolves to the response
 */
proto.SpotifyServicePromiseClient.prototype.getMediaTotalCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SpotifyService/GetMediaTotalCount',
      request,
      metadata || {},
      methodDescriptor_SpotifyService_GetMediaTotalCount);
};


module.exports = proto;

